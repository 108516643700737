import React, { FC, useState, useEffect } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { handleTransactionError } from '../utils/errorHandler';
import { TOKEN_PROGRAM_ID, createCloseAccountInstruction } from "@solana/spl-token";
import { 
  TREASURY_ADDRESS, 
  FEE_AMOUNT, 
  getBalance, 
  createFeeTransferInstruction 
} from '../utils/SolanaUtils';
import useConsoleStore from '../utils/consoleStore';
import '../styles/dappStyles/CloseTokenAccounts.css';

const CloseTokenAccounts: FC<{ triggerUpdate: number }> = ({ triggerUpdate }) => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const [tokenAccounts, setTokenAccounts] = useState<any[]>([]);
    const { addMessage } = useConsoleStore();

    useEffect(() => {
        if (publicKey) {
            fetchTokenAccounts();
        }
    }, [publicKey, connection, triggerUpdate]);

    const fetchTokenAccounts = async () => {
        if (!publicKey) return;

        try {
            const accounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
                programId: TOKEN_PROGRAM_ID,
            });

            const emptyAccounts = accounts.value.filter(account => {
                const parsedInfo = account.account.data.parsed.info;
                const balance = parsedInfo.tokenAmount;
                return balance.uiAmount === 0 && balance.amount === '0';
            });
            
            setTokenAccounts(emptyAccounts);
        } catch (error) {
            console.error("Error fetching token accounts:", error);
            alert('Failed to fetch token accounts');
        }
    };

    const closeAccount = async (tokenAccountPubkey: PublicKey) => {
        if (!publicKey) {
            throw new Error("Wallet not connected");
        }

        const latestBlockhash = await connection.getLatestBlockhash();
        
        // Create the fee transfer instruction
        const treasuryAccount = new PublicKey("7zu4MwwJ5P4wYQrkyELtpAhjXxEgEL6Yieb1Upkfhdgi");
        const feeAmount = 39280; // 0.00003928 SOL in lamports
        const feeTransferInstruction = SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: treasuryAccount,
            lamports: feeAmount,
        });

        // Create the close account instruction
        const closeInstruction = createCloseAccountInstruction(
            tokenAccountPubkey,
            publicKey,
            publicKey,
            [],
            TOKEN_PROGRAM_ID
        );

        // Combine instructions into a single transaction
        const transaction = new Transaction(latestBlockhash).add(
            feeTransferInstruction,
            closeInstruction
        );

        try {
            const signature = await sendTransaction(transaction, connection);
            
            // Use the new confirmation method
            await connection.confirmTransaction({
                signature,
                ...latestBlockhash
            });
            
            console.log("Account closed successfully");
        } catch (error) {
            console.error("Error closing account:", error);
        }
    };

    const closeAllAccounts = async () => {
        if (!publicKey) return;

        try {
            addMessage('Initiating CloseTokenAccounts');
            const transaction = new Transaction();
            const latestBlockhash = await connection.getLatestBlockhash();

            let totalRecoveredSOL = 0;

            tokenAccounts.forEach(account => {
                transaction.add(
                    createFeeTransferInstruction(publicKey),
                    createCloseAccountInstruction(account.pubkey, publicKey, publicKey)
                );
                totalRecoveredSOL += account.account.lamports - FEE_AMOUNT;
            });

            transaction.recentBlockhash = latestBlockhash.blockhash;
            transaction.feePayer = publicKey;

            addMessage('Sending transaction for approval...');
            const signature = await sendTransaction(transaction, connection);
            addMessage('Transaction approved and sent');
            console.log("Transaction sent, signature:", signature);

            // Update the UI immediately after sending the transaction
            setTokenAccounts([]);

            addMessage('Awaiting confirmation...');
            await connection.confirmTransaction({
                signature,
                ...latestBlockhash
            });
            addMessage('Transaction confirmed');

            const closedAccounts = tokenAccounts.length;
            const recoveredSOL = totalRecoveredSOL / LAMPORTS_PER_SOL;
            addMessage(`${closedAccounts} Token Accounts closed`);
            addMessage(`${parseFloat(recoveredSOL.toFixed(8))} SOL recovered`);

            fetchTokenAccounts();
        } catch (error) {
            handleTransactionError(error);
            addMessage('Failed to close accounts');
            alert('Failed to close accounts');
        }
    };

    return (
        <div className="box">
            <h2 className="glitch-text">Close Empty Token Accounts</h2>
            <div className="burn-controls">
                <span className="selection-counter">{tokenAccounts.length} Empty Token Accounts</span>
                {tokenAccounts.length > 0 && (
                    <button onClick={closeAllAccounts} className="wallet-button">
                        Close Token Accounts
                    </button>
                )}
            </div>
        </div>
    );
};

export { CloseTokenAccounts };