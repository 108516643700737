const RPC_ENDPOINTS = [
  'https://mainnet.helius-rpc.com/?api-key=3632daae-4968-4896-9d0d-43f382188194',
  // Add more endpoints as needed
];

let currentEndpointIndex = 0;

export function getNextRpcEndpoint(): string {
  const endpoint = RPC_ENDPOINTS[currentEndpointIndex];
  currentEndpointIndex = (currentEndpointIndex + 1) % RPC_ENDPOINTS.length;
  return endpoint;
}
