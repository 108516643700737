import React, { useState } from 'react';
import '../styles/componentStyles/Footer.css';

const Footer: React.FC = () => {
  const [isMinimized, setIsMinimized] = useState(false);

  return (
    <div className={`floating-footer ${isMinimized ? 'minimized' : ''}`}>
      <button className="minimize-button" onClick={() => setIsMinimized(!isMinimized)}>
        {isMinimized ? '▲' : '▼'}
      </button>
      <div className="footer-content">
        <a href="https://www.x.com/belacosaursol" target="_blank" rel="noopener noreferrer">
          <span className="ascii-logo">&#x2715;</span> Belacosaur X
        </a>
        <a href="https://www.github.com/belacosaur" target="_blank" rel="noopener noreferrer">
          <span className="ascii-logo">&#x25CF;</span> Belacosaur Git
        </a>
      </div>
    </div>
  );
};

export default Footer;