import React, { useState, useEffect } from 'react';
import { PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { TOKEN_PROGRAM_ID, createBurnInstruction, getAssociatedTokenAddress } from "@solana/spl-token";
import { getParsedNftAccountsByOwner } from '@nfteyez/sol-rayz';
import { TREASURY_ADDRESS } from '../utils/SolanaUtils';
import { handleTransactionError } from '../utils/errorHandler';
import useConsoleStore from '../utils/consoleStore';
import { FEE_AMOUNT } from '../utils/SolanaUtils';
import '../styles/dappStyles/BurnNfts.css';

const BurnNfts: React.FC<{ onNFTsBurned: () => void }> = ({ onNFTsBurned }) => {
  const [nfts, setNfts] = useState<any[]>([]);
  const [selectedNfts, setSelectedNfts] = useState<string[]>([]);
  const { publicKey, sendTransaction } = useWallet();
  const { connection } = useConnection();
  const { addMessage } = useConsoleStore();

  useEffect(() => {
    const fetchNFTs = async () => {
      if (publicKey) {
        try {
          const userNFTs = await getParsedNftAccountsByOwner({
            publicAddress: publicKey.toString(),
            connection
          });
          
          const nftsWithMetadata = await Promise.all(userNFTs.map(async (nft: any) => {
            try {
              const response = await fetch(nft.data.uri);
              const metadata = await response.json();
              return { ...nft, metadata };
            } catch (error) {
              console.error('Error fetching NFT metadata:', error);
              return nft;
            }
          }));
          
          setNfts(nftsWithMetadata);
        } catch (error) {
          console.error('Error fetching NFTs:', error);
        }
      }
    };

    fetchNFTs();
  }, [publicKey, connection]);

  const toggleNftSelection = (nftId: string) => {
    setSelectedNfts(prev => {
      if (prev.includes(nftId)) {
        return prev.filter(id => id !== nftId);
      } else if (prev.length < 10) {
        return [...prev, nftId];
      }
      return prev;
    });
  };

  const handleBurn = async () => {
    if (!publicKey) {
      console.error("Wallet not connected");
      return;
    }

    try {
      addMessage('Initiating BurnNFTs');
      const transaction = new Transaction();
      let totalRent = 0;

      for (const nftMint of selectedNfts) {
        const mintPublicKey = new PublicKey(nftMint);
        const tokenAccount = await getAssociatedTokenAddress(mintPublicKey, publicKey);

        // Add burn instruction
        transaction.add(createBurnInstruction(
          tokenAccount,
          mintPublicKey,
          publicKey,
          1
        ));

        // Calculate rent to be recovered
        const mintAccountInfo = await connection.getAccountInfo(mintPublicKey);
        if (mintAccountInfo) {
          totalRent += mintAccountInfo.lamports;
        }
      }

      // Calculate the fee amount for all NFTs being burned
      const totalFeeAmount = FEE_AMOUNT * selectedNfts.length;

      // Calculate the actual amount the user will receive (rounded down to 0.001 SOL)
      const userReceivedAmount = Math.floor(totalRent / LAMPORTS_PER_SOL / 0.001) * 0.001 * LAMPORTS_PER_SOL;
      const treasuryAmount = totalRent - userReceivedAmount;

      // Add transfer instruction for the treasury (fee payment + dust)
      if (treasuryAmount > 0) {
        transaction.add(
          SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: TREASURY_ADDRESS,
            lamports: treasuryAmount,
          })
        );
      }

      addMessage("Sending transaction for approval...");
      const signature = await sendTransaction(transaction, connection);
      addMessage("Transaction approved and sent");
      console.log("Transaction sent:", signature);
      
      addMessage("Awaiting confirmation...");
      const confirmation = await connection.confirmTransaction(signature, 'confirmed');
      
      if (confirmation.value.err) {
        throw new Error("Transaction failed to confirm");
      }

      addMessage('Transaction confirmed');
      addMessage(`${selectedNfts.length} NFTs burned successfully`);
      addMessage(`${(userReceivedAmount / LAMPORTS_PER_SOL).toFixed(3)} SOL recovered`);

      console.log('NFTs burned successfully');
      setSelectedNfts([]);
      setNfts(prevNfts => prevNfts.filter(nft => !selectedNfts.includes(nft.mint)));
      onNFTsBurned(); // Call this function to trigger an update in CloseTokenAccounts
    } catch (error: any) {
      if (error.message.includes("User rejected the request")) {
        console.error("Transaction was rejected by the user");
        addMessage("Transaction was rejected by the user");
      } else {
        console.error("Error burning NFTs:", error);
        addMessage("Error burning NFTs");
        handleTransactionError(error);
      }
    }
  };

  return (
    <div className="box">
      <h2 className="glitch-text">Burn NFT</h2>
      <div className="nft-scroll-container dashboard-scrollable">
        {nfts.map((nft, index) => (
          <div 
            key={index} 
            className={`nft-item ${selectedNfts.includes(nft.mint) ? 'selected' : ''}`}
            onClick={() => toggleNftSelection(nft.mint)}
          >
            <img
              src={nft.metadata?.image || 'placeholder-image-url.jpg'}
              alt={`NFT ${index}`}
              className="nft-thumbnail"
            />
            <span className="nft-info">
              {nft.data.name}
            </span>
          </div>
        ))}
      </div>
      <div className="burn-controls">
        <span className="selection-counter">{selectedNfts.length}/10 selected</span>
        <button 
          className="nft-button" 
          onClick={handleBurn} 
          disabled={selectedNfts.length === 0}
        >
          Burn NFTs
        </button>
      </div>
    </div>
  );
};

export default BurnNfts;