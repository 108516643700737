export const handleTransactionError = (error: unknown) => {
    if (error instanceof Error) {
      if (error.message.includes('User rejected the request')) {
        console.log('Transaction cancelled by user');
      } else {
        console.error('Transaction error:', error.message);
      }
    } else {
      console.error('An unknown error occurred during the transaction');
    }
  };