import { create } from 'zustand';

interface ConsoleState {
  messages: string[];
  addMessage: (message: string) => void;
  clearMessages: () => void;
}

const useConsoleStore = create<ConsoleState>((set) => ({
  messages: [],
  addMessage: (message) => set((state) => ({ messages: [...state.messages, message] })),
  clearMessages: () => set({ messages: [] }),
}));

export default useConsoleStore;