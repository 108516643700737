import React, { useState, useEffect } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import { TREASURY_ADDRESS } from '../utils/SolanaUtils';
import useConsoleStore from '../utils/consoleStore';
import '../styles/dappStyles/HackingSimulator.css';

const CHALLENGE_FEE = 0.001;
interface Challenge {
  id: number;
  description: string;
  solution: string;
  difficulty: 'Easy' | 'Medium' | 'Hard';
}

// Function to remove non-alphanumeric characters and convert to lowercase
const normalizeString = (str: string): string => {
  return str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

// Function to check if two strings are similar (you can adjust the threshold)
const stringSimilarity = (str1: string, str2: string): number => {
  const longer = str1.length > str2.length ? str1 : str2;
  const shorter = str1.length > str2.length ? str2 : str1;
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / longerLength;
};

// Levenshtein distance function (helper for stringSimilarity)
const editDistance = (str1: string, str2: string): number => {
  const m = str1.length;
  const n = str2.length;
  const dp: number[][] = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

  for (let i = 0; i <= m; i++) {
    dp[i][0] = i;
  }
  for (let j = 0; j <= n; j++) {
    dp[0][j] = j;
  }

  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (str1[i - 1] === str2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1];
      } else {
        dp[i][j] = Math.min(dp[i - 1][j - 1], dp[i][j - 1], dp[i - 1][j]) + 1;
      }
    }
  }

  return dp[m][n];
};

const challenges: Challenge[] = [
    // Easy challenges (1-30)
    {
      id: 1,
      description: 'Decode this base64 string: "SGVsbG8gV29ybGQ="',
      solution: 'Hello World',
      difficulty: 'Easy',
    },
    {
      id: 2,
      description: 'What\'s the output of this code: console.log(2 + "2" - 1)',
      solution: '21',
      difficulty: 'Easy',
    },
    {
      id: 3,
      description: 'Convert the binary number 1010 to decimal.',
      solution: '10',
      difficulty: 'Easy',
    },
    {
      id: 4,
      description: 'What does HTML stand for?',
      solution: 'Hypertext Markup Language',
      difficulty: 'Easy',
    },
    {
      id: 5,
      description: 'What\'s the result of 5 % 2 in most programming languages?',
      solution: '1',
      difficulty: 'Easy',
    },
    {
      id: 6,
      description: 'What does CSS stand for?',
      solution: 'Cascading Style Sheets',
      difficulty: 'Easy',
    },
    {
      id: 7,
      description: 'What\'s the correct HTML tag for the largest heading?',
      solution: '<h1>',
      difficulty: 'Easy',
    },
    {
      id: 8,
      description: 'What\'s the protocol used for secure web browsing?',
      solution: 'HTTPS',
      difficulty: 'Easy',
    },
    {
      id: 9,
      description: 'What does API stand for?',
      solution: 'Application Programming Interface',
      difficulty: 'Easy',
    },
    {
      id: 10,
      description: 'What\'s the result of 3 * "2" in JavaScript?',
      solution: '6',
      difficulty: 'Easy',
    },
    {
      id: 11,
      description: 'What\'s the file extension for a Python file?',
      solution: '.py',
      difficulty: 'Easy',
    },
    {
      id: 12,
      description: 'What does CPU stand for?',
      solution: 'Central Processing Unit',
      difficulty: 'Easy',
    },
    {
      id: 13,
      description: 'What\'s the opposite of AND in Boolean logic?',
      solution: 'OR',
      difficulty: 'Easy',
    },
    {
      id: 14,
      description: 'What\'s the result of !true in most programming languages?',
      solution: 'false',
      difficulty: 'Easy',
    },
    {
      id: 15,
      description: 'What does URL stand for?',
      solution: 'Uniform Resource Locator',
      difficulty: 'Easy',
    },
    {
      id: 16,
      description: 'What\'s the default port for HTTP?',
      solution: '80',
      difficulty: 'Easy',
    },
    {
      id: 17,
      description: 'What does IDE stand for in programming?',
      solution: 'Integrated Development Environment',
      difficulty: 'Easy',
    },
    {
      id: 18,
      description: 'What\'s the symbol for single-line comments in most C-style languages?',
      solution: '//',
      difficulty: 'Easy',
    },
    {
      id: 19,
      description: 'What does RAM stand for?',
      solution: 'Random Access Memory',
      difficulty: 'Easy',
    },
    {
      id: 20,
      description: 'What\'s the result of typeof null in JavaScript?',
      solution: 'object',
      difficulty: 'Easy',
    },
    {
      id: 21,
      description: 'What does DOM stand for in web development?',
      solution: 'Document Object Model',
      difficulty: 'Easy',
    },
    {
      id: 22,
      description: 'What\'s the correct file extension for a Java source file?',
      solution: '.java',
      difficulty: 'Easy',
    },
    {
      id: 23,
      description: 'What does SQL stand for?',
      solution: 'Structured Query Language',
      difficulty: 'Easy',
    },
    {
      id: 24,
      description: 'What\'s the result of 1 + 1 + "1" in JavaScript?',
      solution: '21',
      difficulty: 'Easy',
    },
    {
      id: 25,
      description: 'What does PHP stand for?',
      solution: 'Hypertext Preprocessor',
      difficulty: 'Easy',
    },
    {
      id: 26,
      description: 'What\'s the default port for HTTPS?',
      solution: '443',
      difficulty: 'Easy',
    },
    {
      id: 27,
      description: 'What does JSON stand for?',
      solution: 'JavaScript Object Notation',
      difficulty: 'Easy',
    },
    {
      id: 28,
      description: 'What\'s the result of 5 == "5" in JavaScript?',
      solution: 'true',
      difficulty: 'Easy',
    },
    {
      id: 29,
      description: 'What does XML stand for?',
      solution: 'Extensible Markup Language',
      difficulty: 'Easy',
    },
    {
      id: 30,
      description: 'What\'s the correct HTML tag for inserting a line break?',
      solution: '<br>',
      difficulty: 'Easy',
    },
  
    // Medium challenges (31-70)
    {
      id: 31,
      description: 'What\'s the time complexity of a binary search algorithm?',
      solution: 'O(log n)',
      difficulty: 'Medium',
    },
    {
      id: 32,
      description: 'What\'s the difference between == and === in JavaScript?',
      solution: '=== checks type and value, == only checks value',
      difficulty: 'Medium',
    },
    {
      id: 33,
      description: 'What\'s a closure in programming?',
      solution: 'A function with access to its outer function\'s scope',
      difficulty: 'Medium',
    },
    {
      id: 34,
      description: 'What\'s the purpose of the "use strict" directive in JavaScript?',
      solution: 'To enable strict mode',
      difficulty: 'Medium',
    },
    {
      id: 35,
      description: 'What\'s the difference between let and var in JavaScript?',
      solution: 'let is block-scoped, var is function-scoped',
      difficulty: 'Medium',
    },
    {
      id: 36,
      description: 'What\'s a pure function?',
      solution: 'A function that always returns the same output for the same input',
      difficulty: 'Medium',
    },
    {
      id: 37,
      description: 'What\'s the purpose of the async keyword in JavaScript?',
      solution: 'To define an asynchronous function',
      difficulty: 'Medium',
    },
    {
      id: 38,
      description: 'What\'s the difference between null and undefined in JavaScript?',
      solution: 'null is an assigned value, undefined is a lack of assignment',
      difficulty: 'Medium',
    },
    {
      id: 39,
      description: 'What\'s a higher-order function?',
      solution: 'A function that takes or returns another function',
      difficulty: 'Medium',
    },
    {
      id: 40,
      description: 'What\'s the purpose of the this keyword in JavaScript?',
      solution: 'To refer to the current object or context',
      difficulty: 'Medium',
    },
    {
      id: 41,
      description: 'What\'s a promise in JavaScript?',
      solution: 'An object representing the eventual completion of an asynchronous operation',
      difficulty: 'Medium',
    },
    {
      id: 42,
      description: 'What\'s the difference between synchronous and asynchronous programming?',
      solution: 'Synchronous is sequential, asynchronous is non-blocking',
      difficulty: 'Medium',
    },
    {
      id: 43,
      description: 'What\'s a RESTful API?',
      solution: 'An API that follows REST architectural constraints',
      difficulty: 'Medium',
    },
    {
      id: 44,
      description: 'What\'s the purpose of the bind method in JavaScript?',
      solution: 'To set the this value for a function',
      difficulty: 'Medium',
    },
    {
      id: 45,
      description: 'What\'s the difference between call and apply methods in JavaScript?',
      solution: 'call takes arguments separately, apply takes an array of arguments',
      difficulty: 'Medium',
    },
    {
      id: 46,
      description: 'What\'s a callback function?',
      solution: 'A function passed as an argument to another function',
      difficulty: 'Medium',
    },
    {
      id: 47,
      description: 'What\'s the purpose of the Map object in JavaScript?',
      solution: 'To store key-value pairs and maintain insertion order',
      difficulty: 'Medium',
    },
    {
      id: 48,
      description: 'What\'s the difference between localStorage and sessionStorage?',
      solution: 'localStorage persists, sessionStorage clears on tab close',
      difficulty: 'Medium',
    },
    {
      id: 49,
      description: 'What\'s a generator function in JavaScript?',
      solution: 'A function that can be paused and resumed',
      difficulty: 'Medium',
    },
    {
      id: 50,
      description: 'What\'s the purpose of the Object.freeze() method in JavaScript?',
      solution: 'To make an object immutable',
      difficulty: 'Medium',
    },
    {
      id: 51,
      description: 'What\'s the difference between shallow and deep copy?',
      solution: 'Shallow copy references nested objects, deep copy duplicates them',
      difficulty: 'Medium',
    },
    {
      id: 52,
      description: 'What\'s a middleware in the context of web development?',
      solution: 'Software that acts as a bridge between an operating system or database and applications',
      difficulty: 'Medium',
    },
    {
      id: 53,
      description: 'What\'s the purpose of the virtual DOM in React?',
      solution: 'To improve performance by minimizing direct DOM manipulation',
      difficulty: 'Medium',
    },
    {
      id: 54,
      description: 'What\'s the difference between props and state in React?',
      solution: 'Props are passed from parent, state is managed within the component',
      difficulty: 'Medium',
    },
    {
      id: 55,
      description: 'What\'s a closure in JavaScript?',
      solution: 'A function that has access to variables in its outer lexical scope',
      difficulty: 'Medium',
    },
    {
      id: 56,
      description: 'What\'s the purpose of the useEffect hook in React?',
      solution: 'To perform side effects in function components',
      difficulty: 'Medium',
    },
    {
      id: 57,
      description: 'What\'s the difference between SQL and NoSQL databases?',
      solution: 'SQL is relational and structured, NoSQL is non-relational and flexible',
      difficulty: 'Medium',
    },
    {
      id: 58,
      description: 'What\'s a JWT (JSON Web Token)?',
      solution: 'A compact, URL-safe means of representing claims to be transferred between two parties',
      difficulty: 'Medium',
    },
    {
      id: 59,
      description: 'What\'s the purpose of the async/await keywords in JavaScript?',
      solution: 'To simplify asynchronous code and make it look synchronous',
      difficulty: 'Medium',
    },
    {
      id: 60,
      description: 'What\'s the difference between authentication and authorization?',
      solution: 'Authentication verifies identity, authorization grants access rights',
      difficulty: 'Medium',
    },
    {
      id: 61,
      description: 'What\'s a race condition in programming?',
      solution: 'When the behavior of a program depends on the relative timing of events',
      difficulty: 'Medium',
    },
    {
      id: 62,
      description: 'What\'s the purpose of the Object.create() method in JavaScript?',
      solution: 'To create a new object with the specified prototype object and properties',
      difficulty: 'Medium',
    },
    {
      id: 63,
      description: 'What\'s the difference between function declaration and function expression?',
      solution: 'Function declarations are hoisted, function expressions are not',
      difficulty: 'Medium',
    },
    {
      id: 64,
      description: 'What\'s the purpose of the spread operator (...) in JavaScript?',
      solution: 'To expand an iterable into individual elements',
      difficulty: 'Medium',
    },
    {
      id: 65,
      description: 'What\'s a memoization in programming?',
      solution: 'An optimization technique that stores the results of expensive function calls',
      difficulty: 'Medium',
    },
    {
      id: 66,
      description: 'What\'s the difference between TCP and UDP protocols?',
      solution: 'TCP is connection-oriented and reliable, UDP is connectionless and faster',
      difficulty: 'Medium',
    },
    {
      id: 67,
      description: 'What\'s a deadlock in programming?',
      solution: 'A situation where two or more processes are unable to proceed because each is waiting for the other',
      difficulty: 'Medium',
    },
    {
      id: 68,
      description: 'What\'s the purpose of the Object.seal() method in JavaScript?',
      solution: 'To prevent new properties from being added to an object',
      difficulty: 'Medium',
    },
    {
      id: 69,
      description: 'What\'s the difference between GET and POST HTTP methods?',
      solution: 'GET retrieves data, POST submits data to be processed',
      difficulty: 'Medium',
    },
    {
      id: 70,
      description: 'What\'s a CORS (Cross-Origin Resource Sharing) policy?',
      solution: 'A security mechanism that allows or restricts resource requests from other domains',
      difficulty: 'Medium',
    },
  
    // Hard challenges (71-100)
    {
      id: 71,
      description: 'Explain the concept of public key cryptography in one sentence.',
      solution: 'A system where a public key is used for encryption and a private key for decryption',
      difficulty: 'Hard',
    },
    {
      id: 72,
      description: 'What\'s the difference between a thread and a process?',
      solution: 'A process is an independent program, while a thread is a subset of a process',
      difficulty: 'Hard',
    },
    {
      id: 73,
      description: 'Explain the CAP theorem in distributed systems.',
      solution: 'A distributed system can only guarantee two out of three: Consistency, Availability, and Partition tolerance',
      difficulty: 'Hard',
    },
    {
      id: 74,
      description: 'What\'s a blockchain in simple terms?',
      solution: 'A decentralized, distributed ledger technology that records transactions across many computers',
      difficulty: 'Hard',
    },
    {
      id: 75,
      description: 'What\'s the difference between symmetric and asymmetric encryption?',
      solution: 'Symmetric uses one key for both encryption and decryption, asymmetric uses a pair of public and private keys',
      difficulty: 'Hard',
    },
    {
      id: 76,
      description: 'What\'s a Merkle tree and its use in blockchain?',
      solution: 'A tree of hashes used to efficiently verify the integrity of large datasets in blockchains',
      difficulty: 'Hard',
    },
    {
      id: 77,
      description: 'Explain the concept of sharding in databases.',
      solution: 'Horizontally partitioning data across multiple databases to improve performance and scalability',
      difficulty: 'Hard',
    },
    {
      id: 78,
      description: 'What\'s the difference between OAuth and OpenID?',
      solution: 'OAuth is for authorization, OpenID is for authentication',
      difficulty: 'Hard',
    },
    {
      id: 79,
      description: 'What\'s a zero-knowledge proof?',
      solution: 'A method by which one party can prove to another that a statement is true without revealing any information beyond the validity of the statement itself',
      difficulty: 'Hard',
    },
    {
      id: 80,
      description: 'Explain the concept of eventual consistency in distributed systems.',
      solution: 'A consistency model where all replicas eventually reach a consistent state after a period of time',
      difficulty: 'Hard',
    },
    {
      id: 81,
      description: 'What\'s the difference between a compiled and an interpreted language?',
      solution: 'Compiled languages are translated to machine code before execution, interpreted languages are translated at runtime',
      difficulty: 'Hard',
    },
    {
      id: 82,
      description: 'What\'s a side-channel attack?',
      solution: 'An attack based on information gained from the physical implementation of a system, rather than weaknesses in the implemented algorithm itself',
      difficulty: 'Hard',
    },
    {
      id: 83,
      description: 'Explain the concept of idempotency in RESTful APIs.',
      solution: 'The property where multiple identical requests have the same effect as a single request',
      difficulty: 'Hard',
    },
    {
      id: 84,
      description: 'What\'s the difference between a stateless and a stateful protocol?',
      solution: 'Stateless protocols don\'t store client data between requests, stateful protocols do',
      difficulty: 'Hard',
    },
    {
      id: 85,
      description: 'What\'s a man-in-the-middle attack?',
      solution: 'An attack where the attacker secretly relays and possibly alters the communications between two parties',
      difficulty: 'Hard',
    },
    {
      id: 86,
      description: 'Explain the concept of a Turing machine.',
      solution: 'A mathematical model of computation describing an abstract machine that manipulates symbols on a strip of tape according to a table of rules',
      difficulty: 'Hard',
    },
    {
      id: 87,
      description: 'What\'s the halting problem in computer science?',
      solution: 'The problem of determining, from a description of an arbitrary computer program and an input, whether the program will finish running or continue to run forever',
      difficulty: 'Hard',
    },
    {
      id: 88,
      description: 'What\'s a quantum computer and how does it differ from a classical computer?',
      solution: 'A computer that uses quantum-mechanical phenomena to perform operations on data, potentially solving certain problems much faster than classical computers',
      difficulty: 'Hard',
    },
    {
      id: 89,
      description: 'Explain the concept of homomorphic encryption.',
      solution: 'A form of encryption allowing computations to be performed on ciphertext, generating an encrypted result which, when decrypted, matches the result of operations performed on the plaintext',
      difficulty: 'Hard',
    },
    {
      id: 90,
      description: 'What\'s the difference between a virus and a worm in computer security?',
      solution: 'A virus requires user action to spread, while a worm can spread automatically through networks',
      difficulty: 'Hard',
    },
    {
      id: 91,
      description: 'Explain the concept of a Bloom filter.',
      solution: 'A space-efficient probabilistic data structure used to test whether an element is a member of a set',
      difficulty: 'Hard',
    },
    {
      id: 92,
      description: 'What\'s the difference between a mutex and a semaphore?',
      solution: 'A mutex is a locking mechanism used to synchronize access to a resource, a semaphore is a signaling mechanism',
      difficulty: 'Hard',
    },
    {
      id: 93,
      description: 'Explain the concept of a Merkle Patricia Tree used in Ethereum.',
      solution: 'A data structure used to store key-value pairs, optimized for efficient storage and retrieval of data in blockchain systems',
      difficulty: 'Hard',
    },
    {
      id: 94,
      description: 'What\'s a zero-day vulnerability?',
      solution: 'A software vulnerability that is unknown to the software creator and actively exploited by attackers',
      difficulty: 'Hard',
    },
    {
      id: 95,
      description: 'Explain the concept of a smart contract in blockchain technology.',
      solution: 'Self-executing contracts with the terms of the agreement directly written into code',
      difficulty: 'Hard',
    },
    {
      id: 96,
      description: 'What\'s the difference between a stack and a heap in memory allocation?',
      solution: 'Stack is used for static memory allocation and is faster, heap is used for dynamic memory allocation',
      difficulty: 'Hard',
    },
    {
      id: 97,
      description: 'Explain the concept of a Lamport clock in distributed systems.',
      solution: 'A simple logical clock algorithm used to determine the order of events in a distributed computer system',
      difficulty: 'Hard',
    },
    {
      id: 98,
      description: 'What\'s the difference between a white-box and a black-box testing?',
      solution: 'White-box testing examines internal structures of the app, black-box testing examines functionality without knowing internal structures',
      difficulty: 'Hard',
    },
    {
      id: 99,
      description: 'Explain the concept of a consensus algorithm in blockchain.',
      solution: 'A mechanism to achieve agreement on a single data value among distributed processes or systems',
      difficulty: 'Hard',
    },
    {
      id: 100,
      description: 'Write a regular expression to validate an IPv4 address.',
      solution: '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
      difficulty: 'Hard',
    },
  ];

// Function to check answers with flexibility
const checkAnswer = (userAnswer: string, correctAnswer: string): boolean => {
  const normalizedUserAnswer = normalizeString(userAnswer);
  const normalizedCorrectAnswer = normalizeString(correctAnswer);
  return stringSimilarity(normalizedUserAnswer, normalizedCorrectAnswer) > 0.8;
};

const HackingSimulator: React.FC = () => {
  const [currentChallengeIndex, setCurrentChallengeIndex] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [score, setScore] = useState(0);
  const [message, setMessage] = useState('');
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const { addMessage } = useConsoleStore();

  const currentChallenge = challenges[currentChallengeIndex];

  const handleSubmit = () => {
    if (currentChallenge && checkAnswer(userInput, currentChallenge.solution)) {
      // Correct answer logic
      setScore(prevScore => prevScore + 1);
      setMessage('Correct! Moving to next challenge.');
      
      // Move to next challenge
      if (currentChallengeIndex < challenges.length - 1) {
        setCurrentChallengeIndex(prevIndex => prevIndex + 1);
        setUserInput(''); // Clear input for next challenge
      } else {
        setMessage('Congratulations! You\'ve completed all challenges.');
      }
    } else {
      // Incorrect answer logic
      setMessage('Incorrect. Try again!');
    }
  };

  const unlockNextChallenge = async () => {
    if (!publicKey) {
      addMessage('Please connect your wallet first.');
      return;
    }

    try {
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: TREASURY_ADDRESS,
          lamports: CHALLENGE_FEE * 1e9, // Convert SOL to lamports
        })
      );

      const signature = await sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'confirmed');

      addMessage(`Paid ${CHALLENGE_FEE} SOL to unlock next challenge.`);
      const nextChallenge = challenges.find(c => c.id === (currentChallenge?.id || 0) + 1);
      setCurrentChallengeIndex(prevIndex => prevIndex + 1);
    } catch (error) {
      console.error('Error unlocking challenge:', error);
      addMessage('Failed to unlock next challenge. Please try again.');
    }
  };

  return (
    <div className="hacking-simulator">
      <h1>Hacking Simulator</h1>
      <div className="challenge-container">
        {currentChallenge ? (
          <>
            <h2>Challenge {currentChallenge.id}</h2>
            <p>{currentChallenge.description}</p>
            <div className="input-group">
              <input
                type="text"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Enter solution"
              />
              <button onClick={handleSubmit}>Submit</button>
            </div>
            {message && <p className="message">{message}</p>}
          </>
        ) : (
          <p>No more challenges available.</p>
        )}
        <p className="score">Score: {score}</p>
      </div>
      <button className="unlock-button" onClick={unlockNextChallenge}>
        Unlock Next Challenge ({CHALLENGE_FEE} SOL)
      </button>
    </div>
  );
};

export default HackingSimulator;