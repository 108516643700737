import React from 'react';
import '../styles/componentStyles/SpiderMan.css';


const SpiderMan: React.FC = () => {
  return (
    <div className="spiderman-container">
      <img src="/spiderman.gif" alt="Dancing Spider-Man" className="spiderman-gif" />
    </div>
  );
};

export default SpiderMan;