import { PublicKey, Connection, Transaction, SystemProgram } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, createCloseAccountInstruction as createCloseAccountInstructionSPL } from '@solana/spl-token';

// Constants
export const TREASURY_ADDRESS = new PublicKey('7zu4MwwJ5P4wYQrkyELtpAhjXxEgEL6Yieb1Upkfhdgi');
export const FEE_AMOUNT = 39280; // 0.00003928 SOL in lamports

// Utility functions
export const getBalance = async (connection: Connection, publicKey: PublicKey): Promise<number> => {
  const balance = await connection.getBalance(publicKey);
  return balance / 1e9; // Convert lamports to SOL
};

export const createCloseAccountInstruction = (
  tokenAccountPubkey: PublicKey,
  destinationPubkey: PublicKey,
  ownerPubkey: PublicKey
): ReturnType<typeof createCloseAccountInstructionSPL> => {
  return createCloseAccountInstructionSPL(
    tokenAccountPubkey,
    destinationPubkey,
    ownerPubkey,
    [],
    TOKEN_PROGRAM_ID
  );
};

export const createFeeTransferInstruction = (
  fromPubkey: PublicKey,
  toPubkey: PublicKey = TREASURY_ADDRESS,
  lamports: number = FEE_AMOUNT
) => {
  return SystemProgram.transfer({
    fromPubkey,
    toPubkey,
    lamports,
  });
};

// Placeholder for burnNFT function
export const burnNFT = async (publicKey: PublicKey, mintAddress: PublicKey) => {
  console.log('Burn NFT functionality not implemented yet');
  // Implementation will be added later
};

// Add more utility functions as needed
