import React, { useState, useEffect, useMemo } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { TREASURY_ADDRESS } from '../utils/SolanaUtils';
import { handleTransactionError } from '../utils/errorHandler';
import useConsoleStore from '../utils/consoleStore';
import '../styles/dappStyles/Tamagotchi.css';

const REVIVAL_COST = 0.01 * LAMPORTS_PER_SOL; // 0.01 SOL in lamports

const Tamagotchi: React.FC = () => {
  const { publicKey, sendTransaction } = useWallet();
  const [hunger, setHunger] = useState(() => {
    const saved = localStorage.getItem('tamagotchiHunger');
    return saved ? parseInt(saved, 10) : 50;
  });
  const [happiness, setHappiness] = useState(() => {
    const saved = localStorage.getItem('tamagotchiHappiness');
    return saved ? parseInt(saved, 10) : 50;
  });
  const [health, setHealth] = useState(() => {
    const saved = localStorage.getItem('tamagotchiHealth');
    return saved ? parseInt(saved, 10) : 100;
  });
  const [age, setAge] = useState(() => {
    const saved = localStorage.getItem('tamagotchiBirthTime');
    return saved ? Math.floor((Date.now() - parseInt(saved, 10)) / 60000) : 0;
  });
  const [animation, setAnimation] = useState(0);

  // Use the RPC endpoint from environment variables
  const connection = useMemo(() => new Connection(process.env.REACT_APP_RPC_ENDPOINT || ''), []);

  const { addMessage } = useConsoleStore();

  useEffect(() => {
    const timer = setInterval(() => {
      setHunger((prev) => Math.max(0, prev - 2));
      setHappiness((prev) => Math.max(0, prev - 2));
      
      // Calculate health change based on hunger and happiness
      const avgState = (hunger + happiness) / 2;
      let healthChange = 0;

      if (avgState < 20) {
        healthChange = -2; // Health decreases faster in critical condition
      } else if (avgState < 50) {
        healthChange = -1; // Health decreases faster in poor condition
      } else if (avgState > 80) {
        healthChange = 0.2;
      } else if (avgState > 90) {
        healthChange = 0.5;
      }

      setHealth((prev) => Math.max(0, Math.min(100, prev + healthChange)));
      setAnimation((prev) => (prev + 1) % 4);
      
      // Update age only if health > 0, at a constant rate
      setAge((prev) => health > 0 ? prev + 1/6 : prev);
    }, 10000);

    return () => clearInterval(timer);
  }, [hunger, happiness, health]); // Added health to the dependency array

  useEffect(() => {
    localStorage.setItem('tamagotchiHunger', hunger.toString());
    localStorage.setItem('tamagotchiHappiness', happiness.toString());
    localStorage.setItem('tamagotchiHealth', health.toString());
    if (age === 0) {
      localStorage.setItem('tamagotchiBirthTime', Date.now().toString());
    }
  }, [hunger, happiness, health, age]);

  const feed = () => setHunger((prev) => Math.min(100, prev + 10));
  const play = () => setHappiness((prev) => Math.min(100, prev + 10));

  const revive = async () => {
    if (!publicKey) return;

    addMessage('Initiated Tamagotchi Revive');
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: new PublicKey(TREASURY_ADDRESS),
        lamports: REVIVAL_COST,
      })
    );

    try {
      addMessage('Approved Tamagotchi Revive');
      const signature = await sendTransaction(transaction, connection);
      addMessage('Sending transaction');
      console.log('Transaction sent:', signature);
      addMessage('Transaction confirmed');
      // Revive the Tamagotchi immediately after sending the transaction
      setHealth(100);
      setHunger(50);
      setHappiness(50);
      addMessage('Success! Your pet is alive');
    } catch (error) {
      addMessage('Failed to revive Tamagotchi');
      handleTransactionError(error);
    }
  };

  const getCharacter = () => {
    let face;
    if (hunger > 70 && happiness > 70) {
      face = "( ^.^ )"; // Very happy
    } else if (hunger < 30 && happiness < 30) {
      face = "( T.T )"; // Very sad
    } else if (hunger < 30) {
      face = "( o.o')"; // Hungry
    } else if (happiness < 30) {
      face = "( u.u )"; // Unhappy
    } else {
      face = "( o.o )"; // Neutral
    }

    const base = [
      " /\\_/\\ ",
      face,
      " > ^ < "
    ];

    if (animation % 2 === 0) {
      base[2] = " > - < "; // Alternate foot position
    }

    return base.join('\n');
  };

  return (
    <div className="tamagotchi-container">
      <h3>CONSOL FREN</h3>
      <div className="tamagotchi-age">Age: {Math.floor(age)}</div>
      <pre className="tamagotchi-character">{getCharacter()}</pre>
      <div className="tamagotchi-stats">
        <span className="tamagotchi-stat">Hunger: {hunger}</span>
        <span className="tamagotchi-stat">Happiness: {happiness}</span>
        <span className="tamagotchi-stat">Health: {Math.round(health)}</span>
      </div>
      <div className="tamagotchi-controls">
        {health > 0 ? (
          <>
            <button onClick={feed} className="tamagotchi-button">Feed</button>
            <button onClick={play} className="tamagotchi-button">Play</button>
          </>
        ) : (
          <button onClick={revive} className="tamagotchi-button">Revive (0.01 SOL)</button>
        )}
      </div>
    </div>
  );
};

export default Tamagotchi;