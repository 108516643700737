import React, { useState, useEffect, useMemo } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import '../styles/dappStyles/TokenPriceTracker.css';

// Add SOL_MINT constant
const SOL_MINT = 'So11111111111111111111111111111111111111112';

// Add this utility function at the top of the file
const suppressConsoleErrors = (error: any) => {
  if (error.message && (error.message.includes('429') || error.message.includes('Too many requests'))) {
    // Suppress the error
    return;
  }
  // Log other errors as usual
  console.error(error);
};

interface TokenInfo {
  mint: string;
  balance: number;
  price: number | null;
  symbol: string;
  name: string;
  decimals: number;
}

interface TokenMetadata {
  [address: string]: { name: string; symbol: string; decimals: number };
}

const TokenPriceTracker: React.FC = () => {
  const { connection } = useConnection();
  const { publicKey } = useWallet();
  const [tokens, setTokens] = useState<TokenInfo[]>([]);
  const [tokenMetadata, setTokenMetadata] = useState<TokenMetadata>({});

  useEffect(() => {
    fetchTokenList();
  }, []);

  useEffect(() => {
    if (publicKey) {
      fetchTokenAccounts();
    }
  }, [publicKey, connection, tokenMetadata]);

  const fetchTokenList = async () => {
    try {
      const response = await fetch(`https://token.jup.ag/all`);
      const data = await response.json();
      const metadata: TokenMetadata = {};
      data.forEach((token: any) => {
        if (token.decimals > 0) { // Only include tokens with decimals
          metadata[token.address] = { 
            name: token.name, 
            symbol: token.symbol, 
            decimals: token.decimals 
          };
        }
      });
      setTokenMetadata(metadata);
    } catch (error) {
      console.error("Error fetching token list:", error);
    }
  };

  const fetchTokenAccounts = async () => {
    if (!publicKey) return;

    try {
      // Fetch SOL balance
      const solBalance = await connection.getBalance(publicKey);
      const solInfo: TokenInfo = {
        mint: SOL_MINT,
        balance: solBalance / LAMPORTS_PER_SOL,
        price: null,
        symbol: 'SOL',
        name: 'Solana',
        decimals: 9,
      };

      const accounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
        programId: TOKEN_PROGRAM_ID,
      });

      const tokenInfoPromises = accounts.value.map(async (account) => {
        const parsedInfo = account.account.data.parsed.info;
        const mintAddress = parsedInfo.mint;
        const balance = parsedInfo.tokenAmount.uiAmount;

        if (balance > 0 && tokenMetadata[mintAddress]) {
          const metadata = tokenMetadata[mintAddress];
          return {
            mint: mintAddress,
            balance,
            price: null, // We'll fetch prices in bulk later
            symbol: metadata.symbol,
            name: metadata.name,
            decimals: metadata.decimals,
          } as TokenInfo;
        }
        return null;
      });

      let tokenInfo = (await Promise.all(tokenInfoPromises)).filter((token): token is TokenInfo => token !== null);

      // Add SOL to the beginning of the array
      tokenInfo = [solInfo, ...tokenInfo];

      setTokens(tokenInfo);

      // Fetch prices for all tokens at once
      await fetchTokenPrices(tokenInfo);
    } catch (error) {
      suppressConsoleErrors(error);
    }
  };

  const fetchTokenPrices = async (tokenInfo: TokenInfo[]) => {
    try {
      const mintAddresses = tokenInfo.map(token => token.mint);
      const queryString = mintAddresses.join(',');
      const response = await fetch(`https://price.jup.ag/v6/price?ids=${queryString}`);
      const data = await response.json();

      const updatedTokens = tokenInfo.map(token => ({
        ...token,
        price: data.data[token.mint]?.price || null,
      }));

      setTokens(updatedTokens);
    } catch (error) {
      suppressConsoleErrors(error);
    }
  };

  // Calculate total wallet value
  const totalWalletValue = useMemo(() => {
    return tokens.reduce((total, token) => {
      if (token.price !== null) {
        return total + token.balance * token.price;
      }
      return total;
    }, 0);
  }, [tokens]);

  return (
    <div className="box token-price-tracker">
      <h2 className="glitch-text">Your Wallet</h2>
      <div className="token-list-container">
        <div className="token-list dashboard-scrollable">
          <div className="token-item header">
            <span>Token</span>
            <span>Balance</span>
            <span>Price</span>
          </div>
          {tokens.map((token, index) => (
            <div key={index} className="token-item">
              <span className="token-info">
                {token.symbol}
              </span>
              <span>{token.balance.toFixed(token.decimals)}</span>
              <span>{token.price ? `$${(token.balance * token.price).toFixed(2)}` : 'N/A'}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="total-value">
        <hr />
        <div className="total-value-content">
          <span>Total Wallet Value:</span>
          <span>${totalWalletValue.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

export default TokenPriceTracker;
