import React, { useEffect, useRef } from 'react';
import '../styles/componentStyles/Console.css';

interface ConsoleProps {
  messages: string[];
}

const Console: React.FC<ConsoleProps> = ({ messages }) => {
  const consoleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (consoleRef.current) {
      consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="console" ref={consoleRef}>
      {messages.map((message, index) => (
        <div key={index} className="console-line">
          <span className="console-dot">•</span>
          <span className="console-message">{message}</span>
        </div>
      ))}
      <div className="console-line">
        <span className="console-dot blink">•</span>
        <span className="console-message"></span>
      </div>
    </div>
  );
};

export default Console;