import React, { useState, useEffect } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { CloseTokenAccounts } from '../dapps/CloseTokenAccounts';
import BurnNfts from '../dapps/BurnNfts';
import TokenPriceTracker from '../dapps/TokenPriceTracker';
import Tamagotchi from '../dapps/Tamagotchi';
import Console from '../components/Console';
import useConsoleStore from '../utils/consoleStore';
import HackingSimulator from '../dapps/HackingSimulator';
import SpiderMan from '../components/SpiderMan';
import '../styles/App.css'; // Add this import

const Dashboard: React.FC = () => {
  const { publicKey, connected } = useWallet();
  const { connection } = useConnection();
  const [balance, setBalance] = useState<number | null>(null);
  const { messages, addMessage, clearMessages } = useConsoleStore();
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const handleNFTsBurned = () => {
    setUpdateTrigger(prev => prev + 1);
  };

  useEffect(() => {
    if (connected && publicKey) {
      clearMessages();
      addMessage(`Loading Wallet address ${publicKey.toBase58()}`);
      addMessage('Ready...');
    }
  }, [connected, publicKey, addMessage, clearMessages]);

  useEffect(() => {
    if (publicKey) {
      const fetchBalance = async () => {
        const balance = await connection.getBalance(publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      };
      fetchBalance();
    } else {
      setBalance(null);
    }
  }, [publicKey, connection]);

  return (
    <div className="App">
      <Header />
      <main className="dashboard">
        <div className="codec-screen">
          <div className="codec-left">
            <Console messages={messages} />
          </div>
          <div className="codec-center">
            <div className="codec-info">
              <p>Wallet Address: {publicKey ? publicKey.toBase58() : 'Not Connected'}</p>
              <p>Balance: {balance !== null ? `${balance.toFixed(4)} SOL` : 'N/A'}</p>
            </div>
            <div className="codec-controls">
              <span>▼MEM</span>
              <span>◀TUNE▶</span>
            </div>
            <div className="spiderman-wrapper">
              <SpiderMan />
            </div>
          </div>
          <div className="codec-right">
            <Tamagotchi />
          </div>
        </div>
        <div className="dapp-container">
          <TokenPriceTracker />
          <BurnNfts onNFTsBurned={handleNFTsBurned} />
          <CloseTokenAccounts triggerUpdate={updateTrigger} />
          <HackingSimulator />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Dashboard;