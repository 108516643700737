import React from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import '../styles/componentStyles/Header.css';

const Header: React.FC = () => {
  return (
    <header className="appHeader">
      <div className="header-content">
        <div className="logo">
          <h1 className="glitch-text">CONSOL</h1>
        </div>
        <WalletMultiButton className="wallet-adapter-button" />
      </div>
      <nav className="nav">
        {/* Add your navigation items here */}
      </nav>
    </header>
  );
};

export default Header;